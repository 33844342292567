import '../../assets/Layout.scss';

import { Avatar, FloatButton } from 'antd';
import { Link, NavLink, Outlet } from 'react-router-dom';

import ButtonRdv from '../ButtonRdv';
import { PhoneOutlined } from '@ant-design/icons';
import { isNowOpen } from '../../utils/getWorkingHours';

export default function Layout() {
    return (
        <>

            {isNowOpen() &&
                <FloatButton
                    tooltip={isNowOpen() ? "Actuellement ouvert" : "Actuellement fermé"}
                    icon={<PhoneOutlined />}
                    href="tel:+32460214112"

                />
            }
            <header className="headerApp">

                <Avatar size="large" src={<img src={"./doctor.png"} alt="avatar" />} />
                <h1 style={{ flex: 1 }}>
                    <Link to="/">
                        Docteur Elisabeth Boulanger<br />
                        <small>Médecine Générale, agréé et accrédité</small>
                    </Link>
                </h1>
                <ButtonRdv type="default" />

            </header>
            <main>
                <Outlet />

            </main>
            <footer className="footerApp">
                <div className="footerContent">

                    <p>
                        <strong>Docteur Boulanger</strong><br />
                        Médecine générale<br />
                        Rue Hélène de Visscher 1, 5004 Bouge<br />
                        +32.460.214112
                    </p>

                    <iframe
                        title='Google Maps'
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1269.5650831932924!2d4.888572799930575!3d50.475920427790825!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c199a8107a98c5%3A0x77e614b05048e391!2sDocteur%20Elisabeth%20Boulanger!5e0!3m2!1sfr!2sbe!4v1722865628505!5m2!1sfr!2sbe"
                        allowFullScreen={false}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <NavLink to="/privacy">Politique de confidentialité</NavLink>
            </footer>
        </>
    )
}
