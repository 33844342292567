import './assets/App.scss';

import {
    BrowserRouter,
    Navigate,
    Route,
    Routes
} from "react-router-dom";

import { Analytics } from "@vercel/analytics/react"
import { ConfigProvider } from 'antd';
import Home from './components/Home';
import Layout from './components/layout/Layout';
import Privacy from './components/privacy';

function App() {
    return (
        <div className="App">
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#3fb0bb',
                        colorTextSecondary: '#334256',
                        colorBgBase: "#ffffff",

                        // colorLink: '#334256',

                        // colorBgContainer: '#ffffff', //bg color of the boxes
                    },
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route index element={<Home />} />
                            <Route path="privacy" element={<Privacy />} />
                            {/* <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contact" element={<Contact />} /> */}
                        </Route>
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </BrowserRouter>
            </ConfigProvider>
            <Analytics />
        </div>
    );
}

export default App;
