export const workingHours: { day: string, open: boolean, start: number, end: number, message: string, availableByPhone: boolean }[] = [
    {
        day: 'Lundi',
        open: true,
        start: 8,
        end: 18,
        message: '',
        availableByPhone: true
    },
    {
        day: 'Mardi',
        open: true,
        start: 8,
        end: 18,
        message: '',
        availableByPhone: true
    },
    {
        day: 'Mercredi',
        open: false,
        start: 8,
        end: 18,
        message: 'Joignable uniquement par téléphone de 8h à 18h',
        availableByPhone: true
    },
    {
        day: 'Jeudi',
        open: true,
        start: 8,
        end: 18,
        message: '',
        availableByPhone: true
    },
    {
        day: 'Vendredi',
        open: true,
        start: 8,
        end: 18,
        message: '',
        availableByPhone: true
    },
    {
        day: 'Samedi',
        open: false,
        start: 14,
        end: 18,
        message: '',
        availableByPhone: false
    },
    {
        day: 'Dimanche',
        open: false,
        start: 8,
        end: 18,
        message: '',
        availableByPhone: false
    }
]

export const holidays: { from: any, to: any }[] = [
    {
        from: {
            month: 0,
            day: 1,
        },
        to: {
            month: 0,
            day: 1,
        },
    }
    ,
    {
        from: {
            month: 2,
            day: 16,
        },
        to: {
            month: 2,
            day: 24,
        },
    },
    {
        from: {
            month: 3,
            day: 1,
        },
        to: {
            month: 3,
            day: 1,
        },
    },
    {
        from: {
            month: 4,
            day: 1,
        },
        to: {
            month: 4,
            day: 1,
        },
    },

    {
        from: {
            month: 4,
            day: 9,
        },
        to: {
            month: 4,
            day: 10,
        },
    },
    {
        from: {
            month: 6,
            day: 22,
        },
        to: {
            month: 6,
            day: 29,
        },
    }, {
        from: {
            month: 7,
            day: 3,
        },
        to: {
            month: 7,
            day: 18,
        },
    },
    {
        from: {
            month: 8,
            day: 6,
        },
        to: {
            month: 8,
            day: 6,
        },
    },
    {
        from: {
            month: 9,
            day: 1,
        },
        to: {
            month: 9,
            day: 1,
        },
    },
    {
        from: {
            month: 10,
            day: 1,
        },
        to: {
            month: 10,
            day: 1,
        },
    },
    {
        from: {
            month: 10,
            day: 11,
        },
        to: {
            month: 10,
            day: 11,
        },
    },
    {
        from: {
            month: 11,
            day: 6,
        },
        to: {
            month: 11,
            day: 10,
        },
    },
    {
        from: {
            month: 11,
            day: 23,
        },
        to: {
            month: 11,
            day: 29,
        },
    }

]

export const getFrenchMonth = (month: number) => {
    switch (month) {
        case 0:
            return 'Janvier'
        case 1:
            return 'Février'
        case 2:
            return 'Mars'
        case 3:
            return 'Avril'
        case 4:
            return 'Mai'
        case 5:
            return 'Juin'
        case 6:
            return 'Juillet'
        case 7:
            return 'Août'
        case 8:
            return 'Septembre'
        case 9:
            return 'Octobre'
        case 10:
            return 'Novembre'
        case 11:
            return 'Décembre'
        default:
            return ''
    }
}

export const isNowOpen = () => {
    // check if open today depending on the day of the week and the holidays
    const today = new Date();
    const day = today.getDay();
    const month = today.getMonth();
    const date = today.getDate();

    // check if today is a holiday
    const isHoliday = holidays.some(holiday => {
        if (holiday.from.month === month && holiday.to.month === month) {
            return date >= holiday.from.day && date <= holiday.to.day
        }
        return false
    })

    if (isHoliday) return false;

    // check if today is open
    const openToday = workingHours[day].open;
    const availableByPhone = workingHours[day].availableByPhone;
    if (!openToday && !availableByPhone) return false;

    // check if open now
    const now = today.getHours();
    const start = workingHours[day].start;
    const end = workingHours[day].end;
    return now >= start && now < end

}
